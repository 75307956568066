<template>
  <!--begin::Dashboard-->
  <div class="p-5 container">
    <div class="mb-5 row">
      <div class="col-12 col-lg-3 mb-2">
        <el-select
          class="w-100"
          filterable
          v-model="filterForm.category"
          @change="searchData"
          placeholder="หมวดหมู่"
        >
          <el-option value="" label="ทั้งหมด"></el-option>
          <el-option
            v-for="ct in categoryList"
            :key="ct.categoryCode"
            :value="ct.categoryCode"
            :label="ct.categoryName[0].data"
          />
        </el-select>
      </div>
      <div class="col-12 col-lg-3 mb-2">
        <el-select
          class="w-100"
          filterable
          v-model="filterForm.status"
          @change="searchData"
          placeholder="สถานะ"
        >
          <el-option value="" label="ทั้งหมด"></el-option>
          <el-option value="0" label="ลงทะเบียน"></el-option>
          <el-option value="2" label="เรียนต่อ"></el-option>
          <el-option value="3" label="จบหลักสูตร"></el-option>
          <el-option value="4" label="ไม่ผ่านหลักสูตร"></el-option>
          <el-option value="5" label="หมดเวลาเรียน"></el-option>
        </el-select>
      </div>
      <div class="col-12 col-lg-3 mb-2">
        <el-select
          class="w-100"
          filterable
          v-model="filterForm.orderBy"
          @change="searchData"
          placeholder="เรียงตาม"
        >
          <el-option value="" label="ทั้งหมด"></el-option>
          <el-option value="3" label="มาใหม่"></el-option>
          <el-option value="1" label="แนะนำ"></el-option>
        </el-select>
      </div>
      <div class="col-12 col-lg-3 mb-2">
        <input
          class="form-control form-control-lg"
          type="text"
          v-model="filterForm.search"
          @change="searchData"
          autocomplete="one-time-code"
          placeholder="ค้นหา"
        />
      </div>
    </div>
    <div
      class="row mb-5"
      style="padding: 2vh;"
      v-if="listData && listData.length > 0"
    >
      <div
        class="col-12 col-lg-4 mb-5"
        v-for="(item, index) in listData"
        :key="index"
      >
        <router-link :to="'/course/voluntary/' + item.id">
          <div
            class="card"
            style="border-radius: 10px;position: relative;border: 1px solid #F2E2E1;min-height: 300px"
          >
            <img
              :src="imageUrl + item.thumbnailsPath"
              @error="setAltImg"
              alt=""
              class="w-100"
              style="border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              object-fit: cover;
              min-height: 200px;"
            />
            <div class="card-body" style="min-height: 180px;">
              <!-- <div class="mb-5 ">
                  <button type="button" class="btn btn-app">
                    {{ item.courseTypeName }}
                  </button>
                </div> -->
              <div
                class="mb-5 limit-text section-header"
                style="font-weight: 700;
                  line-height: 30px;
                  color: #323563;"
              >
                {{ item.title }}
              </div>
              <div>
                หมวดหมู่ :
                <span v-html="renderCategory(item.categoryProfile)"></span>
              </div>
              <div
                class="mb-5 limit-text"
                style="font-weight: 400;
                  color: #9F9F9F;"
                v-html="removeStyle(item.description)"
              ></div>
            </div>
            <div class="card-footer">
              <div
                class="d-flex align-items-center flex-column mt-3 w-100"
                v-if="item.register == true"
              >
                <div
                  class="d-flex justify-content-between fw-bold fs-6 text-dark w-100 mt-auto mb-2"
                >
                  <span
                    >สถานะ :
                    <span v-html="renderStatus(item.statusRegister)"></span
                  ></span>
                  <span>{{ item.progressUpdate }}%</span>
                </div>
                <div
                  class="h-8px mx-3 w-100 rounded"
                  style="background:#E1F2FF"
                >
                  <div
                    class="bg-progressbar rounded h-8px"
                    data-bs-toggle="tooltip"
                    :title="item.progressUpdate + '%'"
                    role="progressbar"
                    :style="'width: ' + item.progressUpdate + '%'"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div v-else style="display: flex;justify-content: center;">
                <router-link
                  :to="'/course/voluntary/' + item.id"
                  type="button"
                  class="btn btn-register2"
                >
                  ลงทะเบียน
                </router-link>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class=" row mb-5" v-else>
      <img src="media/image-app/no data.svg" alt="" style="height:300px" />
    </div>
    <ul class="pagination" v-if="listData && listData.length > 0">
      <li :class="'page-item previous ' + (page == 0 ? 'disabled' : '')">
        <button type="button" @click="backPage()" class="page-link">
          <i class="previous"></i>
        </button>
      </li>
      <div v-for="(item, index) in totalPage" :key="index">
        <li :class="'page-item ' + (index == page ? 'active' : '')">
          <button type="button" @click="changePage(item)" class="page-link">
            {{ item }}
          </button>
        </li>
      </div>
      <li
        :class="'page-item next ' + (page + 1 == totalPage ? 'disabled' : '')"
      >
        <button type="button" @click="nextPage()" class="page-link">
          <i class="next"></i>
        </button>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import axios from "axios";
const video = ref();
const canvas = ref();
const captures = ref({});
const isCameraOpen = ref(false);
const listData = ref();
const limit = ref(6);
const page = ref(0);
const orderBy = ref(1);
const sortBy = ref(1);
const categories = ref([]);
const totalPage = ref(0);
const filterForm = ref({
  category: "",
  status: "",
  orderBy: "3",
  search: "",
});

export default defineComponent({
  name: "exam",
  components: {},
  data() {
    return {
      categoryList: [],
      imageUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  setup() {
    onMounted(() => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      getDataList();
    });
    const getDataList = () => {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/getCourse?sizeContents=" +
            limit.value +
            "&page=" +
            page.value +
            "&orderBy=" +
            orderBy.value +
            "&sort=" +
            filterForm.value.orderBy +
            "&category=" +
            filterForm.value.category +
            "&status=" +
            filterForm.value.status +
            "&keyWord=" +
            filterForm.value.search,
          {
            headers: {
              token: localStorage.getItem("id_token")
                ? localStorage.getItem("id_token")
                : localStorage.getItem("tokenGuest"),
            },
          }
        )
        .then((response) => {
          // console.log(response);
          listData.value = response.data.data.content;
          listData.value["totalElements"] = response.data.data.totalElements;
          totalPage.value = response.data.data.totalPages;
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    const changePage = (pageEvent) => {
      // console.log(pageEvent);
      page.value = pageEvent - 1;
      getDataList();
    };
    const backPage = () => {
      page.value = page.value - 1;
      getDataList();
    };
    const nextPage = () => {
      page.value = page.value + 1;
      getDataList();
    };
    const searchData = () => {
      getDataList();
    };
    return {
      video,
      canvas,
      captures,
      isCameraOpen,
      getDataList,
      changePage,
      backPage,
      nextPage,
      listData,
      limit,
      page,
      orderBy,
      sortBy,
      categories,
      totalPage,
      filterForm,
      searchData,
    };
  },
  methods: {
    removeStyle(html) {
      const regex = /(style=".+?")/gm;
      const str = html;
      const subst = ``;
      const result = str.replace(regex, subst);
      return result;
    },
    setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
    /* eslint-disable */
    renderCategory(list) {
      let data: any = [];
      for (const looplist of list) {
        data.push(looplist[0].data);
      }
      if (data.length > 0) {
        return data.join(",");
      } else {
        return "-";
      }
    },
    renderStatus(status) {
      if (status == 1 || status == 2) {
        return "<span style='color:#FFE454'>เรียนต่อ</span>";
      } else if (status == 3) {
        return "<span style='color:#42FB91'>จบหลักสูตร</span>";
      } else if (status == 4) {
        return "<span style='color:#F998A2'>ไม่ผ่านหลักสูตร</span>";
      } else if (status == 5) {
        return "<span style='color:#ECAE51'>หมดเวลาเรียน</span>";
      } else {
        return "-";
      }
    },
  },
  async mounted() {
    const category = await axios.get(
      process.env.VUE_APP_API_URL + "/category?categoryType=course",
      {
        headers: {
          token: localStorage.getItem("id_token")
            ? localStorage.getItem("id_token")
            : localStorage.getItem("tokenGuest"),
        },
      }
    );
    // console.log(category);
    this.categoryList = category.data.data;
  },
});
</script>
